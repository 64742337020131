@import "material-icons/iconfont/material-icons.css";
@import "https://cdn.skypack.dev/@studio-freight/lenis@0.1.12";


:root {
  background-color: rgba(193, 157, 86, 0.273);
  color: #fff;
  /* zoom:90%; */

}
:root::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: url(guitarr.png);
  background-size: 20%;
  background-position-x: 100%;
  background-repeat: no-repeat;
  opacity: 0.5;
  position: fixed;
  
}

.App {
  border-radius: 12px;
  position: relative;
  width: 100%;
  
}

@media screen and (max-width: 1000px) {
  

  :root::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: url(guitarr.png);
    background-size: 60%;
    background-position-x: 100%;
    background-position-y: 0cm;

    background-repeat: no-repeat;
    opacity: 0.5;
    position: fixed;
  }
}
@font-face {
  font-family: Lovelyn;
  src: url('./fonts/Lovelyn.ttf');
}
@font-face {
  font-family: Merriweather-Black;
  src: url('./fonts/Merriweather-Black.ttf');
}
@font-face {
  font-family: Merriweather-BlackItalic;
  src: url('./fonts/Merriweather-BlackItalic.ttf');
}
@font-face {
  font-family: Merriweather-Bold;
  src: url('./fonts/Merriweather-Bold.ttf');
}
@font-face {
  font-family: Merriweather-BoldItalic;
  src: url('./fonts/Merriweather-BoldItalic.ttf');
}
@font-face {
  font-family: Merriweather-Italic;
  src: url('./fonts/Merriweather-Italic.ttf');
}
@font-face {
  font-family: Merriweather-Light;
  src: url('./fonts/Merriweather-Light.ttf');
}
@font-face {
  font-family: Merriweather-LightItalic;
  src: url('./fonts/Merriweather-LightItalic.ttf');
}
@font-face {
  font-family: Merriweather-Regular;
  src: url('./fonts/Merriweather-Regular.ttf');
}

